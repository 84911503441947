<template>
  <div class="layout">
    <div class="title">
      <div class="tit-box">
        <tit-box></tit-box>
      </div>
    </div>
    <div class="main">
      <router-view></router-view>
    </div>
    <div class="footer">
      <div class="footer-box">
        <footer-box></footer-box>
      </div>
    </div>
  </div>
</template>

<script>
import titBox from "./component/title.vue";
import footerBox from "./component/footer.vue";
export default {
  components: { titBox, footerBox }
};
</script>

<style lang="scss" scoped>
.layout {
  min-width: 1400px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > .title {
    width: 100%;
    height: 60px;
    background: #222124;
    .tit-box {
      width: 1400px;
      margin: 0 auto;
    }
  }
  .main {
    flex: 1;
  }
  .footer {
    width: 100%;
    height: 391px;
    background-color: #222124;
    .footer-box {
      width: 1400px;
      margin: 0 auto;
    }
  }
}
</style>