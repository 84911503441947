<template>
  <div class="title">
    <div class="tit-left">
      <img :src="logo" style="margin-right: 120px;" class="logo" @click="goHome" />
      <el-menu :default-active="defaultActive" class="el-menu-demo" active-text-color="#ffffff" router mode="horizontal" background-color="#222124" text-color="#fff">
        <el-menu-item index="/home">首页</el-menu-item>
        <el-submenu index="/numeracy">
          <template slot="title">产品</template>
          <el-menu-item class="aaa" index="/numeracy">数智双碳平台</el-menu-item>
          <el-menu-item class="aaa" index="/carbonFootprint">产业碳足迹平台</el-menu-item>
        </el-submenu>
        <el-submenu index="/zeroCarbonCity">
          <template slot="title">解决方案</template>
          <el-menu-item class="aaa" index="/zeroCarbonCity">零碳城市</el-menu-item>
          <el-menu-item class="aaa" index="/IndustrialCarbon">产业碳足迹</el-menu-item>
          <el-menu-item class="aaa" index="/zeroCarbonPark">零碳园区</el-menu-item>
          <!-- <el-menu-item index="/zeroCarbonMechanism">零碳机关</el-menu-item> -->
          <el-menu-item class="aaa" index="/energyConsumption">能碳监管</el-menu-item>
        </el-submenu>
        <el-menu-item index="/joinUs">加入我们</el-menu-item>
        <el-menu-item index="/aboutUs">关于我们</el-menu-item>
      </el-menu>
      <!-- <ul class="menu">
        <li>首页</li>
        <li>
          产品
          <ul class="menu-item">
            <li>数智双碳平台—亿碳通</li>
            <li> 产业碳足迹平台</li>
          </ul>
        </li>
        <li>解决方案</li>
        <li>加入我们</li>
        <li>关于我们</li>
      </ul>-->
    </div>
    <div class="tit-rigth" @click="GET_ISSHOW(true)">免费试用</div>

    <el-dialog title="免费试用" custom-class="dialog" @open="lockScroll" :visible="isShow" width="552px" :before-close="handleClose" :modal="false">
      <div class="active" @click="go(1)">企碳通</div>
      <div class="active" @click="go(2)">政碳通</div>
      <div class="active" @click="go(4)">核证平台</div>
      <el-button class="button active" disabled @click="go(3)">产品碳足迹</el-button>
    </el-dialog>
  </div>
</template>

<script>
import logo from "@/assets/logo.svg";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      active: null,
      logo: logo,
      defaultActive: "/home"
    };
  },
  watch: {
    $route(to, from) {
      this.defaultActive = to.meta.active;
    }
  },
  created() {
    this.defaultActive = this.$route.meta.active;
  },
  computed: {
    ...mapState(["isShow"])
  },
  methods: {
    ...mapMutations(["GET_ISSHOW"]),
    handleClose() {
      // this.isShow = false;
      this.GET_ISSHOW(false);
      document.body.style.overflow = "";
    },
    goHome() {
      if (this.defaultActive != "/home") {
        this.$router.push("/home");
      }
    },
    lockScroll() {
      document.body.style.overflow = "hidden";
    },
    go(type) {
      this.active = type;
      if (type == 1) {
        window.open("http://enterprise.etantong.com/");
      } else if (type == 2) {
        window.open("http://government.etantong.com/");
      } else if (type == 3) {
        // window.open("http://operation.etantong.com/");
      } else {
        window.open("http://certify.etantong.com/");
      }
    }
  }
};
</script>

<style lang="scss">
.dialog .el-dialog__wrapper {
  background-color: transparent; /* 修改为你想要的颜色 */
}

.aaa:hover {
  background-color: #00dd62 !important;
}
::v-deep .el-menu-item.is-active,
.el-submenu.is-active > .el-submenu__title {
  border-bottom: none !important;
}
.button {
  width: 242px;
  height: 70px;
  margin-top: 23px !important;
  border: none !important;
  background: url("@/assets/border.png") !important;
  span {
    color: #fff;
  }
}

.el-menu-demo {
  border: none !important;

  .el-menu-item {
    border: none !important;
    padding: 0px;
    overflow: hidden;
    margin: 0px 20px !important;
    font-family: PingFangSC-Regular, PingFang SC;
  }

  .el-submenu {
    margin: 0px 20px;
    font-family: PingFangSC-Regular, PingFang SC;

    &.el-menu-item:hover {
      background-color: #00dd62 !important;
    }

    .el-submenu__title {
      border: none !important;
    }
  }

  .is-active {
    font-weight: bold;
    border: none !important;
    position: relative;
    &::after {
      content: "";
      width: 30px;
      height: 3px;
      background: url("@/assets/topLing.png");
      position: absolute;
      background-repeat: no-repeat;
      background-position: center;
      bottom: 10px;
      left: 50%;
      transform: translateX(-70%);
    }
  }

  .el-submenu__title {
    padding: 0px;
  }
}

.menu {
  display: flex;
  > li {
  }
  .menu-item {
    position: absolute;
    > li {
      width: 200px;
      height: 25px;
      line-height: 30px;
      background-color: #222124;
      padding: 5px;
    }
  }
}

.dialog {
  width: 552px !important;
  height: 314px !important;
  background: rgba(34, 33, 36) !important;
  border-radius: 8px !important;

  .el-dialog__header {
    margin: 0px auto;
    width: 505px;
    box-sizing: border-box;
    color: #fff;
    border-bottom: 1px solid;
    border-image: linear-gradient(
        90deg,
        rgba(213, 223, 216, 1),
        rgba(236, 241, 238, 0)
      )
      1 1;
    .el-dialog__headerbtn {
      .el-dialog__close {
        color: #fff;
      }
    }

    span {
      display: block;
      text-align: center;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 33px;
    }
  }
  .el-dialog__body {
    display: flex;
    flex-wrap: wrap;
    div:nth-child(2n) {
      margin-right: 0px;
    }
    div {
      margin-right: 22px;
      margin-top: 23px;
      width: 242px;
      height: 70px;
      background: url("@/assets/border.png");
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    > div:hover {
      background: url("@/assets/border-s.png") !important;
      color: #00dd62 !important;
      span {
        color: #00dd62 !important;
      }
    }
    > button:hover {
      background: url("@/assets/border-s.png") !important;
      color: #00dd62 !important;
      span {
        color: #00dd62 !important;
      }
    }
  }
}
.title {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .tit-left {
    height: 100%;
    display: flex;
    align-items: center;
    flex: 1;

    .logo {
      width: 128px;
      height: 34px;
      cursor: pointer;
    }
  }

  .tit-rigth {
    width: 104px;
    height: 34px;
    color: #222124;
    background-color: #00dd62;
    font-size: 14px;
    text-align: center;
    line-height: 34px;
    border-radius: 4px;
    cursor: pointer;
  }
  .tit-rigth:hover {
    background-color: #17f87c;
  }
}
</style>